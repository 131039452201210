import React from 'react';
import { graphql } from 'gatsby';

import Website from 'components/ui-setup';
import IE from 'components/ie';

type Props = {
  data: Object,
};

const IEPage = ({ data }: Props) => {
  const { ieJson, logoJson } = data;

  return (
    <Website
      content={{
        ie: ieJson,
        logo: logoJson,
      }}>
      <IE />
    </Website>
  );
};

export default IEPage;

export const query = graphql`
  query iePageQuery {
    ieJson {
      image {
        xs
        lg
      }
      title
      text
      buttonLink
      buttonLabel
      meta {
        description
        title
      }
    }
    logoJson {
      colorful
      white
    }
  }
`;
